<template>
  <section>

    <b-row class="mb-2">
      <b-col
        cols="8"
        offset-md="2"
      >
        <b-row class="pr-2 pl-2">
          <section id="faq-search-filter">
            <b-card
              no-body
              class="faq-search p-2"
            >
              <main>
                <div class="cod-voucher">
                  <p>Código do indicador: {{ infoVoucher.indicatorCampaignToken }}</p>
                </div>

                <div class="description">
                  <feather-icon
                    icon="GiftIcon"
                    size="25"
                    class="mr-1"
                    style="color: #FFF"
                  />
                  <h2>{{ infoVoucher.voucherDescription }}</h2>
                </div>

              </main>
            </b-card>
          </section>
        </b-row>
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <validation-observer ref="generateLead">
            <b-form
              class="px-5 form-validate"
              @submit.prevent
            >
              <b-row class="pr-2 pl-2">
                <b-col cols="6">
                  <div class="d-flex mb-2">
                    <feather-icon
                      icon="CircleIcon"
                      size="15"
                      class="mr-1"
                    />

                    <validation-provider
                      #default="{ errors }"
                      name="nome do indicado"
                      rules="required"
                    >
                      <p><strong>Nome do indicado</strong></p>
                      <b-form-input
                        v-model="nomeLead"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                        placeholder="Informe o nome"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>

                  <div class="d-flex mb-2">
                    <feather-icon
                      icon="CircleIcon"
                      size="15"
                      class="mr-1"
                    />
                    <validation-provider
                      #default="{ errors }"
                      name="email do indicado"
                      rules="required|email"
                    >
                      <p><strong>Email do indicado</strong></p>
                      <b-form-input
                        v-model="emailLead"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                        placeholder="jose@gmail.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="d-flex mb-2">
                    <feather-icon
                      icon="CircleIcon"
                      size="15"
                      class="mr-1"
                    />
                    <validation-provider
                      #default="{ errors }"
                      name="telefone do indicado"
                      rules="required|integer|min:10"
                    >
                      <p><strong>Telefone do indicado</strong></p>
                      <b-form-input
                        v-model="telefoneLead"
                        :state="errors.length > 0 ? false:null"
                        type="tel"
                        placeholder="Informe o telefone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div class="d-flex mb-2">
                    <feather-icon
                      icon="CircleIcon"
                      size="15"
                      class="mr-1"
                    />
                    <p><strong>Nome do Indicador</strong> <br>{{ infoVoucher.indicatorName }}</p>
                  </div>
                  <div class="d-flex mb-2">
                    <feather-icon
                      icon="CircleIcon"
                      size="15"
                      class="mr-1"
                    />
                    <p><strong>Recompensa do Indicador</strong> <br>
                      {{ infoVoucher.indicatorRewardMoneyFormat === 'fixed' ? `R$ ${infoVoucher.indicatorReward},00` : '' }}
                      {{ infoVoucher.indicatorRewardMoneyFormat === 'percent' ? `${infoVoucher.indicatorReward}% sobre o valor da venda` : '' }}
                      {{ infoVoucher.indicatorRewardMoneyFormat === '' ? infoVoucher.indicatorReward : '' }}
                    </p>
                  </div>
                </b-col>
                <b-col class="mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click.prevent="validationForm"
                  >
                    Gerar cupom
                    <feather-icon
                      icon="ArrowRightIcon"
                      size="15"
                      class="ml-1"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>

      </b-col>
    </b-row>

    <!--modal erro-->
    <b-modal
      v-model="showModalErro"
      ok-title="Voltar"
      centered
      ok-only
    >
      <div class="modal-erros">
        <feather-icon
          icon="AlertTriangleIcon"
          size="60"
          class="text-danger"
        />
        <h2>Ocorreu um erro.</h2>
        <p>
          {{ msgError }}
        </p>
      </div>
    </b-modal>

  </section>

</template>

<script>

import useJwt from '@/auth/jwt/useJwt'
import { mapGetters } from 'vuex'

import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import removeSpecialChars from '@/utils/removeSpecialChars'

import {
  BRow, BCol, BCard, BButton, BFormInput, BForm, BModal, BOverlay,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  required, email, integer, min,
} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      integer,
      min,
      nomeLead: '',
      emailLead: '',
      telefoneLead: '',
      infoVoucher: {},
      msgError: '',
      showModalErro: false,
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  mounted() {
    localize('pt_BR')

    const queryParam = this.$route.query.infoVoucher
    const describe = JSON.parse(atob(queryParam))
    this.infoVoucher = describe
  },
  methods: {
    validationForm() {
      this.$refs.generateLead.validate().then(success => {
        if (success) {
          this.infoVoucher = {
            ...this.infoVoucher,
            indicatedName: this.nomeLead,
            indicatedEmail: this.emailLead,
            indicatedPhone: this.telefoneLead,
          }

          useJwt.createCoupon({
            indicatorCampaignToken: this.infoVoucher.indicatorCampaignToken,
            indicatedName: this.infoVoucher.indicatedName,
            indicatedEmail: this.infoVoucher.indicatedEmail,
            indicatedPhone: removeSpecialChars(this.infoVoucher.indicatedPhone),
            utm: {
              source: 'dashboard',
              medium: 'dashboard',
              term: 'dashboard',
              campaign: 'dashboard',
              content: 'dashboard',
            },
          })
            .then(
              response => {
                useJwt.validateCoupon(response.data.result.couponToken)
                  .then(
                    responseCouponValidate => {
                      const infoVoucher = {
                        couponToken: responseCouponValidate.data.result.couponToken,
                        couponType: responseCouponValidate.data.result.couponType,
                        voucherDescription: responseCouponValidate.data.result.campaign.benefitIndicated.benefit,
                        expirationDate: responseCouponValidate.data.result.expirationDate ? toConvertDateTimezoneToConventionalDate(responseCouponValidate.data.result.expirationDate) : '',
                        // expirationDate: toConvertDateTimezoneToConventionalDate(responseCouponValidate.data.result.expirationDate),

                        indicatedName: responseCouponValidate.data.result.campaign.benefitIndicated.name,
                        indicatedEmail: responseCouponValidate.data.result.campaign.benefitIndicated.email,
                        indicatedPhone: responseCouponValidate.data.result.campaign.benefitIndicated.phoneNumber,

                        indicatorName: responseCouponValidate.data.result.campaign.benefitIndicator.name,
                        indicatorReward: responseCouponValidate.data.result.campaign.benefitIndicator.type === 'voucher'
                          ? responseCouponValidate.data.result.campaign.benefitIndicator.description : responseCouponValidate.data.result.campaign.benefitIndicator.amount,
                        indicatorRewardMoneyFormat: responseCouponValidate.data.result.campaign.benefitIndicator.format,

                      }
                      const queryParam = btoa(JSON.stringify(infoVoucher))

                      this.$router.push({
                        name: 'confirm-data',
                        query: { infoVoucher: queryParam },
                      })
                    },
                  )
                  .catch(
                    error => {
                      const mensagemErro = error.response.data.errors[0]
                      this.msgError = mensagemErro
                      this.showModalErro = true
                    },
                  )
              },
            )
            .catch(
              error => {
                const mensagemErro = error.response.data.errors[0]
                this.msgError = mensagemErro
                this.showModalErro = true
              },
            )
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-faq.scss';

    #faq-search-filter{
        display: flex;
        width: 100%;

        .faq-search {
            display: flex;
            width: 100%;
            background-color: #C467F0 !important;
        }

        main{
            display: grid;
            grid-template-columns: 2;

            grid-template-areas:"cod cod"
                                "desc desc";

            .cod-voucher { grid-area: cod; }
            .description { grid-area: desc; text-align: center; display: flex; align-items: center; justify-content: center;}

            p {
              color: #FFF;
              font-size: 1.25rem;
              font-weight: 400;
            }

            h2 {
              color: #FFF;
              font-size: 2rem;
              font-weight: 600;
              margin: 1rem 0;
            }
        }
    }

    .form-validate {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15rem;

        .form-group {
            flex: 1;
            margin-right: 1rem;
        }
    }

        .modal-erros{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h2{
        margin: 1rem 0;
        font-size: 1.4rem;
      }
    }
</style>
